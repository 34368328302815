
var im
$(document).on('click', '.frm-new-user .h-link', function(){
  var url = $(this).data('url')
  
  Rails.ajax({
    type: 'patch',
    url: url,
  })
})

$(document).on('click', '.frm-confirmation-user .img-mask', function(){
  $('.input-profile-picture').trigger('click')
})

$(document).on('change', '.input-profile-picture', function(){
  var msg = validateProfilePicture($(this)[0].files[0]) 
  if (msg == '') {
    var target = $(this).data('target')
    
    $('fieldset#'+target+' .img-frame img').hide()
    $('fieldset#'+target+' .img-frame').prepend(animatedLoader())
    Rails.fire(this.form, 'submit')
  } else {
    $('.msg.profile_picture').text(msg)
  }
})

$(document).on('click', '.frm-my-profile label.change-pass', function() {
  var url = $(this).data('url')
  
  Rails.ajax({
    type: 'patch',
    url: url
  })
})

/****************************
 * Magazine Navigator *
*****************************/

$(document).on('click','.magazine-navigator .nav-left, .magazine-navigator .nav-right', function(){
  var page = $(this).attr('data-page')
  var direction = $(this).find('.nav-buttom').attr('data-direction')
  
  magazineNavigation(page, direction)
  
  $('.flip-container').trigger('flipZoom')
  
})

function magazineNavigation(page, direction) {
  
  
  
  pages = []
  
  switch(page) {
    case 'capa':
      if ( direction == 'next') {
        switch ( $('.magazine .interna').length ) {
          case 0 : 
            pages = ( $('.magazine .page#contra-capa').length == 1 ? ['contra-capa'] : ['capa'])
            break
          case 1 : 
            pages = [1, 'contra-capa']
            break
          default:
            pages = [1,2]
        }
      }
      break
    case 'contra-capa':
      if ( direction == 'prev') {
        if ($('.magazine .interna').length >= 2) {
          if ( $('.flip-container').hasClass('narrow')) {
            pages = [parseInt($('.magazine .interna').last().attr('id'))  ]
          } else {
            pages = [parseInt($('.magazine .interna').last().attr('id')) -1 , parseInt($('.magazine .interna').last().attr('id'))]
          }
        } else {
          pages = ['capa']
        }
      }
      break
    default:
      if(direction == 'next') {
        if (page == $('.magazine .interna').last().attr('id') ) {
          pages = ['contra-capa']
        } else {
          pages = [parseInt(page) + 1, ($('.magazine .interna.page#'+ (parseInt(page) + 2)).length > 0 ? parseInt(page) + 2 : 'contra-capa')]
        }
      }
      if(direction == 'prev') {
        if( parseInt(page) == 1 ) {
          pages = ['capa']
        } else {
          if( $('.flip-container').hasClass('narrow')) {
            pages = [parseInt(page) - 1]
          } else {
            pages = [parseInt(page) - 2, parseInt(page) - 1]
          }
        }
      }
  }
  
  
  if ( $('.flip-container').hasClass('narrow')) {
    pages = [pages[0]]
  
  }
  
  if(direction == 'next' || direction == 'prev') {
    $('.magazine .page').hide()
    jQuery.each(pages, function(i){
      if ( $('.magazine .page#'+pages[i]).length > 0 ) {
        $('.magazine .page#'+pages[i]).show().css('display', 'flex')
        
        
      }
    })
    if(pages.length == 2) {
      $('.magazine .magazine-navigator .nav-left').attr('data-page',  pages[0])
      $('.magazine .magazine-navigator .nav-left .left-buttom').attr('data-direction', 'prev')
      $('.magazine .magazine-navigator .nav-right').attr('data-page', pages[1])
      $('.magazine .magazine-navigator .nav-right .right-buttom').attr('data-direction', 'next')
    } else {
      $('.magazine .magazine-navigator .nav-left').attr('data-page',  pages[0])
      $('.magazine .magazine-navigator .nav-left .left-buttom').attr('data-direction', 'prev')
      $('.magazine .magazine-navigator .nav-right').attr('data-page', pages[0])
      $('.magazine .magazine-navigator .nav-right .right-buttom').attr('data-direction', 'next')
    }
    if (pages[0] == 'capa') {
      $('.magazine .magazine-navigator .nav-left').attr('data-page', 'capa')
      $('.magazine .magazine-navigator .nav-right').attr('data-page', 'capa')
      $('.magazine .magazine-navigator .nav-left .left-buttom').attr('data-direction', 'none')
      if ( $('.magazine .interna').length > 0 || $('.magazine #contra-capa').length > 0 ) {
        $('.magazine .magazine-navigator .nav-right .right-buttom').attr('data-direction', 'next')
      } else {
        $('.magazine .magazine-navigator .nav-right .right-buttom').attr('data-direction', 'none')
      }
    }
    if (pages[0] == 'contra-capa' ) {
      $('.magazine .magazine-navigator .nav-left').attr('data-page', 'contra-capa')
      $('.magazine .magazine-navigator .nav-right').attr('data-page', 'contra-capa')
      $('.magazine .magazine-navigator .nav-right .right-buttom').attr('data-direction', 'none')
      $('.magazine .magazine-navigator .nav-left .left-buttom').attr('data-direction', 'prev')
    }
    if (pages[1] == 'contra-capa'){
      $('.magazine .magazine-navigator .nav-left').attr('data-page',  pages[0])
      $('.magazine .magazine-navigator .nav-right').attr('data-page', 'contra-capa')
      $('.magazine .magazine-navigator .nav-right .right-buttom').attr('data-direction', 'none')
    }
  }
  
}

$(document).on('click', '.label-link', function(){
  var url = $(this).data('url')
  var e = $(this)
  
  Rails.ajax({
    type: 'patch',
    url: url, 
    success: function(data){
      $(e).find('i').hide()
    },
    beforeSend: function(xhr) {
      $(e).find('i').show()
      return true
    },
    error: function(data) {
      console.log(data)
    }
  })
})

$(document).on('change', '.frm-set-plano .pgmento .radio-group-icons input', function(){
  
  var tipo = $('.frm-set-plano .pgmento .radio-group-icons input:checked')
  var valor = $('.frm-set-plano .pgmento .radio-group-icons input:checked').val()
  
  $('.frm-set-plano .pgmento .radio-group-icons .button-image').removeClass('selected')
  
  $(tipo).parents('.button-image').addClass('selected')
  
  $('.frm-set-plano .panel').hide()
  $('.frm-set-plano .panel.'+valor).show()
})


$(document).on('change', '.frm-set-plano .plano .radio-group input', function(){
  
  var meses = $('.frm-set-plano .plano .radio-group input:checked').val()
  var total = $('.frm-set-plano .plano .radio-group input:checked').data('total')
  
  $('.frm-set-plano input#assinatura_ps_meses').val(meses)
  
  $('.frm-set-plano .forma #total').text(total)
})


$(document).on('change', '.frm-set-plano .forma .radio-group-icons input', function(){
  var forma = $('.frm-set-plano .forma .radio-group-icons input:checked').val()

  if ( forma == 'Compra') {
    $('.frm-set-plano .plano').show()
  } else {
    $('.frm-set-plano .plano').hide()    
  }
  
})

//card token
$(document).on('change', '.frm-card-token .grupo input', function(){
  var value = $('.frm-card-token .card-brand input[name=card_brand]:checked').val()
  //console.log(value)
  if ( value == 'undefined') {
    $('.frm-card-token .adesao-fields').hide()
  } else {
    $('.frm-card-token .adesao-fields').show()
  }
  
  var cvv = 'CVV:'
  var card_mask = new Inputmask("9999 9999 9999 99[99]", {  "placeholder" : " "  })
  var cvv_mask = new Inputmask("999[9]", {  "placeholder" : " "  })
  var validade_mask = new Inputmask("99/99", {  "placeholder" : "_"  })

  $('.card-data #label-cvv').text(cvv)
  
  card_mask.mask($('.frm-card-token input#card_number'))
  cvv_mask.mask($('.frm-card-token input#card_cvv'))
  validade_mask.mask($('.frm-card-token input#card_expiration'))
  
  cpf_mask = new Inputmask('999.999.999-99')
  cpf_mask.mask('.frm-card-token input#sender_cpf')
  birth_mask = new Inputmask('99/99/9999')
  birth_mask.mask('.frm-card-token input#sender_birth_date')
  ddd_mask = new Inputmask("99")
  ddd_mask.mask('.frm-card-token input#sender_phone_areacode')
  cep_mask = new Inputmask("99.999-999")
  cep_mask.mask('.frm-card-token input#sender_addess_postalCode')
  uf_mask = new Inputmask('aa')
  uf_mask.mask('.frm-card-token input#sender_addess_state')
  $('.frm-card-token #sender_addess_country').text('BR')
})


//validação cartão

var card  = require('card-validator')
debug = card
$(document).on('keyup', '.frm-card-token input#card_number', function(e){
  var v = $(this).val().replace(/\D/g,'')
  valid = card.number(v)
  
  if (valid.isPotentiallyValid || valid.isValid) {
    $(this).css('color', '#666')
  } else {
    $(this).css('color', 'red')
  }
  $('.frm-card-token label#card-msg').text('')
})

$(document).on('change', '.frm-card-token input#card_number', function(){
  var v = $(this).val().replace(/\D/g,'')
  valid = card.number(v)
  
  if( valid.isValid ) {
    $(this).css('color', '#666')
    $('.frm-card-token label#card-msg').css('color', '#666')
    $('.frm-card-token label#card-msg').text(valid.card.niceType)
    $('.frm-card-token input#r_card_brand').val(valid.card.niceType)
  } else {
    $(this).css('color', '#red')
    $('.frm-card-token label#card-msg').css('color', 'red')
    $('.frm-card-token label#card-msg').text('Número inválido')
  }
  
})

$(document).on('change', '.frm-card-token input#sender_addess_postalCode', function(){
  var cep = $(this).val().replace(/\D/g,'')
  var url = $(this).data('url')
  Rails.ajax({
    type: 'patch',
    url: url,
    data: 'cep='+cep,
    success: function(data){
      $('.frm-card-token .ico.load-cep i').hide()
      //console.log(data)
      if ('error' in data ) {
        $('input#sender_addess_postalCode').css('color', 'red')
        $('#sender_addess_postalCode.msg label').text('Inválido')
      } else if (Object.keys(data).length == 0) {
        $('input#sender_addess_postalCode').css('color', 'red')
        $('#sender_addess_postalCode.msg label').text('Inválido')
        $('input#sender_addess_street').val('')
        $('input#sender_addess_district').val('')
        $('input#sender_addess_city').val('')
        $('input#sender_addess_state').val('')
        
      } else {
        $('input#sender_addess_postalCode').css('color', '#666')
        $('#sender_addess_postalCode.msg label').text('')
        $('.address-fields').show()
        $('input#sender_addess_street').val(data.address)
        $('input#sender_addess_district').val(data.neighborhood)
        $('input#sender_addess_city').val(data.city)
        $('input#sender_addess_state').val(data.state)
        
        $('input#sender_addess_number').focus()
      }
    },
    error: function(data) {
      $('.frm-card-token .ico.load-cep i').hide()
      $('.address-fields').show()
    },
    beforeSend: function(xhr) {
      $('.frm-card-token .ico.load-cep i').show()
      return true
    }
  })
  
})

$(document).on('keyup', '.frm-card-token form input', function() {
  
  var en = true
  
  $('.frm-card-token form input[type=text], .frm-card-token form input[type=date]').each( function(){
    
    if ($(this).attr('id') != 'sender_addess_complement' &&  $(this).val() == '' ) {
      en = false
    }
  })
  
  if (en) {
    $('.frm-card-token .btn-form input[type=submit]').prop('disabled', false)
  } else {
    $('.frm-card-token .btn-form input[type=submit]').prop('disabled', true)
  }
})


$(document).on('click', '.frm-card-token .btn-form input[type=submit]', function(){
  
  $('.frm-card-token .ico-adesao').css('display', 'inline')
})


$(document).on('resizeMagazine', '.flip-container', function(){
  
  var h = $('.main').height()
  var w = $('.main').width()
  var img_h = $('.capa img').height()
  var img_w = $('.capa img').width()
  //$('.flip-container .magazine').css('height', h)
  //$('.flip-container .magazine').css('width', w)
  
  
  
})


$(window).one('load', '.capa img', function(){
  img_h = $('.capa img').height()
  img_w = $('.capa img').width()
 
})


//zoom
var zoom = require('jquery-zoom')
var autoZoom = false
$(document).on('flipZoom', '.flip-container', function(){
  $('.flip-container .page').each(function(){
    if($(this).is(':visible') && autoZoom ) {        
      $(this).zoom({ url: $(this).data('full-url')} )
    } else {
      $(this).trigger('zoom.destroy')  
    }    
  })
  
})

$(document).on('click','.magazine-actions li.zoom', function() {
  /*
  $('.magazine-actions li.zoom').prop('auto-zoom', !$(this).prop('auto-zoom'))
  
  autoZoom = $(this).prop('auto-zoom')
  
  
  if (autoZoom) {
    $('.magazine-actions li.zoom').css({'background-color': '#999', 'color': '#fff'})
    $('.nav-left .left-buttom').css('display', 'flex')
    $('.nav-right .right-buttom').css('display', 'flex')
  } else {
    $('.magazine-actions li.zoom').css({'background-color': '#fff', 'color': '#999'})
    $('.nav-left .left-buttom').prop('style', '')
    $('.nav-right .right-buttom').prop('style', '')
  }
  $('.flip-container').trigger('flipZoom')
  */
  
  if( $('.main.signed').hasClass('magazine-zoom') ) {
    $('.main.signed').removeClass('magazine-zoom')
  } else {
    $('.main.signed').addClass('magazine-zoom')
  }
  
  if ($(this).hasClass('active')) {
    $(this).removeClass('active')
  } else {
    $(this).addClass('active')
    fullImages()
  }  
})

function fullImages() {
  var img
  var full
  $('.page').each(function(){
    img = $(this).find('img').attr('src')
    full = $(this).attr('data-full-url')
    
    if ( img != full ) {
      $(this).find('img').attr('src', full)
      
    }
    
  })
}

$(document).on('click','.magazine-actions li.prev', function() {
  $('.nav-left').trigger('click')
})

$(document).on('click','.magazine-actions li.next', function() {
  $('.nav-right').trigger('click')
})

$(document).on('keydown', function(e){
  //console.log(e.which)
  if ( e.which == 39 ) {
    $('.nav-right').trigger('click')
  }
  if ( e.which == 37 ) {
    $('.nav-left').trigger('click')
  }
})

$(document).on('click', '.magazine-actions li.download', function(){
  //$(this).find('#dowload-edition')[0].click()
  window.location = document.getElementById('dowload-edition').href
})



$(window).on('resize', function(){
  //console.log($(window).width())
  var w = $(window).width()
  var mw = $('.main').width()
  /*
  if ($('.flip-container').length > 0 ) {
    if( w > mw ) {
      $('.main-container').css({'margin-left': (w - mw)/2 * -1 + 50, 'margin-right': (w - mw)/2 * -1 + 50  } )
    } else { 
      $('.main-container').css({'margin-left': 0, 'margin-right': 0  } )
    }
  } else {
    $('.main-container').css({'margin-left': 0, 'margin-right': 0  } )
  }
  */
})

//edições anteriores
$(document).on('change', '.frm-signed-old-editions .filter.year input[type=radio]', function() {
  var ano = $(this).filter(":checked").val()
  var url = $(this).parents('ul.years').data('url')
  $('.filter.year .years li').removeClass('checked').addClass('unchecked')
  $(this).parents('li').removeClass('unchecked').addClass('checked')
  
  $('.frm .action .filter.month').html('<div style="text-align: center; width: 100%">'+animatedLoader()+'</div>')
  
  $('.filter.month').html('<div style="text-align: center; width: 100%">'+animatedLoader()+'</div>')
  $('.tb-list').html('')
  
  Rails.ajax({
    type: 'patch',
    url: url, 
    data: 'year='+ano,
    error: function(data){
      $('.frm .action .filter.month').html('<div class=msg style="text-align: center">Erro!</br>Não foi possivel executar a requisição.</div>')
      console.log(data)
    }
  })
})

$(document).on('change', '.frm-signed-old-editions .action .filter.month input[type=radio]', function(){
  var month = $(this).filter(":checked").val()
  var year = $(this).parents('ul.months').data('year')
  var url = $(this).parents('ul.months').data('url')
  
  $('.filter.month .months li').removeClass('checked').addClass('unchecked')
  $(this).parents('li').removeClass('unchecked').addClass('checked')
  
  $('.frm-signed-old-editions .tb-list').html('<div style="text-align: center; width: 100%">'+animatedLoader()+'</div>')
  
  Rails.ajax({
    type: 'patch',
    url: url,
    data: 'year='+year+'&month='+month,
    error: function(data){
      $('.frm .tb-list').html('<div class=msg style="text-align: center">Erro!</br>Não foi possivel executar a requisição.</div>')
      console.log(data)
    }
  })
  
}) 

$(document).on('click', '.frm-signed-old-editions .tb-list .edicao-thumb', function(){
  
  var url = $(this).data('url')
  
  Rails.ajax({
    type: 'patch',
    url: url,
    error: function(){
      alert('Não foi possiver carregar a edição selecionada')
    }
  })
  
})

$(document).on('click', '.old-edition-actions .back', function(){
  var url = $(this).data('url')
  
  Rails.ajax({
    type: 'patch',
    url: url
  }) 
})


//my-profile
$(document).on('change', '.frm-my-profile .user-edit input[type=text]', function(){
  Rails.fire(this.form, 'submit')
})


$(document).on('click', '.frm-my-profile .profile-data .attachment', function() {
  $('.frm-my-profile .profile-data .f-image input[type=file]').trigger('click')
})

$(document).on('focus', '.frm-my-profile .profile-data input, .frm-my-profile .profile-data textarea', function(){
  $('.frm-my-profile .profile-data #tabindex').val($(this).attr('tabindex'))
})

$(document).on('change', '.frm-my-profile .profile-data input, .frm-my-profile .profile-data textarea', function(){
  Rails.fire(this.form, 'submit')
})

//chat
function resizeChatMsgs() {
  
  
  
  
  $('.main-area').css('max-height', '100%')
  
  var ch = $('.frm-chat .chat-container').position().top
  $('.frm-chat .chat-container').css('height', 'calc(100% - '+ch+'px)')
  
  var hs = $('.frm-chat .send-area').height()
  var cont_h = $('.frm-chat .chat-container').height()
  $('.frm-chat .chat-messages').css('height', 'calc('+cont_h+'px - '+hs+'px )' )
  
  
  /*
  var ht = $('.frm-chat .chat-messages').position().top
  var hs = $('.frm-chat .send-area').height()
  var ha = $('.frm-chat').innerHeight() 
  
  var h = (hs + ht) + 'px'
  
  $('.frm-chat .chat-messages').css('height', 'calc(100% - '+h+')')
  */
}

$(document).on('DOMSubtreeModified', '.frm-chat #chat_msg', function(){
  
  $('.chat-container .simplebar-content-wrapper').scrollTop($('.chat-container .simplebar-content').height())
  
})

$(document).on('DOMSubtreeModified', '.frm-chat .chat-container .send-area', function(){
  
  $('.frm-chat .chat-container').scrollTop($('.frm-chat .chat-container').height())
})

$(document).on('contentChange', '.frm-chat .chat-messages .content', function(){
  $('.chat-container .simplebar-content-wrapper').scrollTop($('.frm-chat .chat-messages .content').height())

})

$(document).on('frmChatShow','.frm-chat', function(){
  resizeChatMsgs()
})

$(document).on('toBottom', '.chat-container .chat-messages', function(){
  $('.chat-container .chat-messages').scrollTop($('.chat-container .chat-messages .content').height())
})

$(document).on('loadChatImages', '.chat-container', function(){
  
  var tid = setTimeout(loadChatImages, 500)
  var count = 0
  var max = 0
  
  $('.chat-container .chat-messages').trigger('toBottom')
  
  function loadChatImages() {
    $('.chat-message img').each(function(i) {
      count = 0
      
      if($(this).height() > 0 ) {
        count = count + 1
        $('.chat-container .chat-messages').trigger('toBottom')
      }
    })
    if ( max <= 5) { 
      tid = setTimeout(loadChatImages, 500)
      max = max + 1
    } else {
      clearTimeout(tid)
      $('.chat-container .chat-messages').trigger('toBottom')
    }
  }  
  
})

$(document).on('loadMessages', '.frm-chat', function(){
  
  
  
  var url = $(this).attr('data-url')
  var last = 0 //id da última mensagem exibida
  
  //console.log('load', url)
  
  
  Rails.ajax({
    type: 'patch',
    url: url,
    data: 'last_chat='+last,
    success: function(){
      
    },
    error: function() {
      
    }, 
    complete: function() {
      $('.chat-container').trigger('loadChatImages')
    }
  })
})


$(document).on('turbolinks:load', function(){
    
    if ( $('.frm-chat').length > 0 ) {
      $('.frm-chat').trigger('frmChatShow')
      $('.frm-chat').trigger('loadMessages')
    }
})

$(window).on('resize', function() {
  if( $('.main-area .flip-container').length > 0 ) {
    setFlipMode()
  }
})

function setFlipMode() {
  if ($(window).width() > 1023 ) {
    $('.main-area .flip-container').removeClass('narrow')
    $('.main-area .flip-container').addClass('large')
  } else {
    $('.main-area .flip-container').removeClass('large')
    $('.main-area .flip-container').addClass('narrow')
  }
}

var s_x
var s_y
var e_x
var e_y
var t_start 
$(document).on('touchstart', '.flip-container', function(e){
  t_start = e.originalEvent.timeStamp;
  if(e.originalEvent.targetTouches.length == 1 ) {
    s_x = e.originalEvent.targetTouches[0].pageX
    s_y = e.originalEvent.targetTouches[0].pageY
  }
})


$(document).on('touchend', '.flip-container', function(e) {
  $('.magazine-navigator .nav-buttom').hide()
  var timeOut = e.originalEvent.timeStamp - t_start
  
  //$('.sn-h-menu .header-gravatar strong').text(timeOut)
  if(e.originalEvent.changedTouches.length == 1 && timeOut < 500) {
    e_x = e.originalEvent.changedTouches[0].pageX
    e_y = e.originalEvent.changedTouches[0].pageY
    if(  ( Math.abs(s_x - e_x) > 30 && Math.abs(s_x - e_x) < 350) ) {
      if(s_x - e_x > 0 ) {
        $(this).find('.nav-right').trigger('click')
      } else {
        $(this).find('.nav-left').trigger('click')
      }
    }
  }
})

/***************************************
 * animate loader
 * **************************/

function animatedLoader() {
  var h =  `<div class="loader loader--style8 thumb-container">
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     width="84px" height="60px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
    <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
      <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
      <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
      <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
    </rect>
    <rect x="8" y="10" width="4" height="10" fill="#333"  opacity="0.2">
      <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
      <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
      <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
    </rect>
    <rect x="16" y="10" width="4" height="10" fill="#333"  opacity="0.2">
      <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
      <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
      <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
    </rect>
  </svg>
</div>`
 return h
}
 
